<template>
  <div class="flex items-center justify-center">
    <div class="sm:w-max-2xl w-full">
      <div class="flex flex-row justify-between items-center mb-8">
        <span class="text-2xl font-bold"
          >{{ !isUpdating ? "Create" : "Update" }} A Game</span
        >
        <div class="flex">
          <vs-button
            v-if="game.deleted"
            success
            @click="reCreateDialog = !reCreateDialog"
          >
            ReCreate
          </vs-button>
          <vs-button v-else danger @click="active = !active">
            Delete
          </vs-button>
          <vs-button to="/games" class="ml-2"> Return </vs-button>
        </div>
      </div>

      <div class="flex flex-col gap-4">
        <vs-input
          type="text"
          placeholder="Game Name"
          v-model="game.name"
          block
        />
        <vs-input
          type="text"
          placeholder="Game Short Name"
          v-model="game.shortName"
          block
        />
        <vs-input
          type="text"
          placeholder="Game Steam ID"
          v-model="game.steamID"
          block
        />
        <div class="flex items-center">
          <vs-input
            type="url"
            placeholder="Game Banner"
            v-model="game.banner"
            block
          />
          <vs-switch class="w-1/2 ml-2" v-model="game.hidden">
            Hidden
          </vs-switch>
        </div>
      </div>

      <div class="footer-dialog mt-4">
        <vs-button v-if="!isUpdating" @click="createGame()" block>
          Create
        </vs-button>
        <vs-button v-else @click="updateGame()" block> Update </vs-button>
      </div>
    </div>

    <vs-dialog width="550px" not-center v-model="active">
      <template #header>
        <h4 class="not-margin">Are you sure you want to delete this game?</h4>
      </template>

      <template #footer>
        <div class="flex justify-end">
          <vs-button color="success" @click="deleteGame" transparent>
            Yes
          </vs-button>
        </div>
      </template>
    </vs-dialog>

    <vs-dialog width="550px" not-center v-model="reCreateDialog">
      <template #header>
        <h4 class="not-margin">Are you sure you want to reCreate this game?</h4>
      </template>

      <template #footer>
        <div class="flex justify-end">
          <vs-button color="success" @click="reCreateGame" transparent>
            Yes
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import {
  createGame,
  deleteGame,
  getGameById,
  reCreateGame,
  updateGame,
} from "@/services/ApiService";

export default {
  name: "CreateGame",
  data: () => ({
    game: {
      name: undefined,
      shortName: undefined,
      banner: undefined,
      steamID: undefined,
      hidden: false,
      deleted: false,
    },
    isUpdating: false,
    active: false,
    reCreateDialog: false,
  }),
  methods: {
    createGame() {
      const gameData = {
        name: this.game.name,
        shortName: this.game.shortName,
        steamID: this.game.steamID,
        banner: this.game.banner,
        hidden: this.game.hidden,
      };

      createGame(gameData)
        .then(() => {
          this.$vs.notification({
            color: "success",
            name: "Success",
            text: "Game created successfully",
          });

          this.$router.push("/games");
        })
        .catch((error) => {
          this.$vs.notification({
            color: "danger",
            name: "Error",
            text: error.response.data.message,
          });
        });
    },
    updateGame() {
      const gameData = {
        name: this.game.name,
        shortName: this.game.shortName,
        steamID: this.game.steamID,
        banner: this.game.banner,
        hidden: this.game.hidden,
      };

      updateGame(this.game._id, gameData)
        .then(() => {
          this.$vs.notification({
            color: "success",
            name: "Success",
            text: "Game successfully updated!",
          });

          this.$router.push("/games");
        })
        .catch((error) => {
          this.$vs.notification({
            color: "danger",
            name: "Error",
            text: error.response.data.message,
          });
        });
    },
    getCurrentGame() {
      getGameById(this.$route.params.id).then((res) => {
        this.game = res;
      });
    },
    deleteGame() {
      this.active = false;
      deleteGame(this.game._id)
        .then(() => {
          this.$vs.notification({
            color: "success",
            name: "Success",
            text: "Game successfully deleted!",
          });

          this.$router.push("/games");
        })
        .catch((error) => {
          this.$vs.notification({
            color: "danger",
            name: "Error",
            text: error.response.data.message,
          });
        });
    },
    reCreateGame() {
      this.reCreateDialog = false;

      reCreateGame(this.game._id)
        .then(() => {
          this.$vs.notification({
            color: "success",
            name: "Success",
            text: "Game successfully recreated!",
          });

          this.$router.back();
        })
        .catch((error) => {
          this.$vs.notification({
            color: "danger",
            name: "Error",
            text: error.response.data.message,
          });
        });
    },
  },
  async mounted() {
    const { id } = this.$route.params;
    if (id) {
      await this.getCurrentGame();
      this.isUpdating = true;
    }
  },
};
</script>
